(function ($) {
  /**
   * MPP alt image toggler
   * Options are defined within the CMS and are extracted on page load.
   */
  Drupal.behaviors.mppCustomProductLayout = {
    attach: function (context) {
      var self = this,
        $anchorLink = $('.js-anchor-link', context);

      $productGridContent = $('.product-grid__content', context);

      $anchorLink.on('click', function () {
        $(window).scrollTop($productGridContent.offset().top);
      });
    }
  };
})(jQuery);
